<template>
  <div class="content">
    <div class="tou">
      <h3>消费品行业数字化解决方案系列产品</h3>
      <img src="@/assets/img/health0.jpg" alt="" class="img1" />
    </div>
    <template v-for="(item, i) in data">
      <div :key="i" class="contentArr">
        <div class="contentText">
          <div class="gang"></div>
          <h3>{{ item.title }}</h3>
          <p>{{ item.text }}</p>
          <!-- 标签 -->
          <div class="care">
            <template v-for="(num, n) in item.careArr">
              <div :key="n" class="careContent">
                {{ num }}
              </div>
            </template>
          </div>
          <!-- 标签 -->
          <img :src="item.imgSrc" alt="" class="img1" :id="'img' + i" />
        </div>
        <div class="below">
          <!-- 案例 -->
          <div class="case">
            <h5>客户案例</h5>
            <div class="caseIcon">
              <template v-for="(temp, v) in item.iconArr">
                <img :key="v" :src="temp" />
              </template>
            </div>
            <!-- <img src="@/assets/img/icon1.png" />
            <img src="@/assets/img/icon2.png" />
            <img src="@/assets/img/icon3.png" /> -->
          </div>
          <!-- 案例 -->
          <!-- 扫码 -->
          <div class="saoma">
            <h5>扫码了解产品详情</h5>
            <img src="@/assets/img/erweima.png" />
          </div>
          <!-- 扫码 -->
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .content {
    .tou {
      text-align: center;
      // .img1 {
      //   display: none;
      // }
      .img1 {
        width: 18.33rem;
        height: 8.7rem;
        margin: 0.2rem 0.46rem;
      }
      h3 {
        font-size: 0.41rem;
        font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
        font-weight: 400;
        color: #093c7f;
        line-height: 0.46rem;
        margin-top: 0.46rem;
      }
    }
    .contentArr:nth-child(even) {
      background-color: #f4f4f4;
    }
    .contentArr {
      background: #fff;
      overflow: hidden;
      width: 100%;
      .contentText {
        width: 16.44rem;
        margin: auto;
        margin-top: 1.54rem;
        .gang {
          width: 0.8rem;
          height: 0.1rem;
          background-color: #093c7f;
        }
        h3 {
          font-size: 0.92rem;
          font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
          font-weight: bold;
          color: #093c7f;
        }
        p {
          font-size: 0.72rem;
          font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
          font-weight: 400;
          color: #4f4f4f;
          line-height: 1rem;
          margin: 0.5rem 0;
        }
        .careContent {
          font-size: 0.61rem;
          font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
          font-weight: 400;
          color: #093c7f;
          margin-bottom: 0.2rem;
        }
        .img1 {
          width: 16.44rem;
          height: 9.01rem;
          margin-top: 0.8rem;
        }
      }
      .below {
        width: 16.44rem;
        margin: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.77rem;
        .case {
          h5 {
            font-size: 0.51rem;
            font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
            font-weight: 400;
            color: #454545;
            line-height: 0.72rem;
            margin: 0.77rem 0 0.51rem;
          }
          img {
            width: 2.66rem;
            height: 2.66rem;
            display: inline-block;
            margin-right: 0.61rem;
          }
        }
        .saoma {
          h5 {
            font-size: 0.51rem;
            font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
            font-weight: 400;
            color: #454545;
            line-height: 0.72rem;
            margin: 0.77rem 0 0.51rem;
          }
          img {
            width: 2.66rem;
            height: 2.66rem;
            margin-left: 1.5rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  .content {
    .tou {
      width: 19.2rem;
      height: 9.3rem;
      h3 {
        height: 1.1rem;
        font-size: 0.64rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #093c7f;
        line-height: 0.75rem;
        margin-top: 1.5rem;
        margin-left: 4.34rem;
      }
      img {
        width: 11rem;
        margin-left: 4.06rem;
        margin-top: 0.82rem;
      }
    }
    .contentArr:nth-child(even) {
      background-color: #f4f4f4;
    }
    .contentArr {
      background: #fff;
      // height: 10rem;
      width: 19.2rem;
      overflow: hidden;
      //position: relative;
      .contentText {
        width: 100%;
        // height: 4.6rem;
        float: left;
        margin: 1.37rem 0 0.15rem 1.67rem;
        position: relative;
        .gang {
          width: 0.8rem;
          height: 0.1rem;
          background: #093c7f;
        }
        h3 {
          height: 0.42rem;
          font-size: 0.36rem;
          font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
          font-weight: bold;
          color: #093c7f;
          line-height: 0.42rem;
          margin-top: 0.05rem;
        }
        p {
          //text-indent: 0.4rem;
          margin-top: 0.19rem;
          width: 7.95rem;
          font-size: 0.2rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 0.36rem;
        }
        .img1 {
          width: 7.3rem;
          height: 4.01rem;
          position: absolute;
          top: 0.22rem;
          right: 3rem;
        }
        #img3 {
          width: 3.55rem;
          height: 3.92rem;
          right: 5rem;
        }
        .care {
          font-size: 0.18rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #093c7f;
          line-height: 0.35rem;
          margin-top: 0.2rem;
          .careContent {
            // margin-bottom: 0.2rem;
            width: 7.95rem;
          }
        }
      }
      .below {
        width: 100%;
        margin-left: 1.67rem;
        overflow: hidden;
        display: flex;
        .case {
          width: 46.5%;
          // height: 3.2rem;
          margin: 0.7rem 0 1.52rem;
          h5 {
            height: 0.37rem;
            font-size: 0.24rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #000;
            line-height: 0.28rem;
            margin-bottom: 0.25rem;
          }
          .caseIcon {
            display: flex;
            flex-wrap: wrap;
            margin-right: 1.5rem;
            img {
              // float: left;
              width: 1rem;
              height: 1rem;
              margin: 0 0.11rem 0.11rem;
            }
            img:hover {
              box-shadow: 0px 0px 0.1rem 0px rgba(53, 66, 134, 0.3);
            }
          }
        }
        .saoma {
          width: 50%;
          margin: 0.7rem 0 1.52rem;
          h5 {
            height: 0.37rem;
            font-size: 0.24rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #000;
            line-height: 0.28rem;
            margin-bottom: 0.25rem;
          }
          img {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
  }
}
</style>
